//@ts-nocheck
import { registerApplication, start } from 'single-spa'

const routes = [
  '/login',
  '/checkout',
  '/password-recovery',
  '/login/sem-escritorio',
  '/invitation-accepted',
  '/meu-escritorio',
  '/peticoes/view/',
  '/peticoes/editor/',
  '/jurisprudencia/view/',
  '/calculos/',
  '/beneficio-por-incapacidade',
  '/plano'
]

const isActive = (location) => {
  // Verifica se a URL atual não corresponde a nenhuma das rotas acima
  return !routes.some((route) => location.pathname.startsWith(route))
}

registerApplication({
  name: '@prev-front/mf-core',
  app: () => System.import('@prev-front/mf-core'),
  activeWhen: [ '/login', '/checkout', '/password-recovery', '/login/sem-escritorio', '/invitation-accepted']
})

registerApplication({
  name: '@prev-front/meu-escritorio',
  app: () => System.import('@prev-front/meu-escritorio'),
  activeWhen: ['/meu-escritorio']
})

registerApplication({
  name: '@prev-front/planos',
  app: () => System.import('@prev-front/planos'),
  activeWhen: ['/plano']
})

registerApplication({
  name: '@prev-front/mf-template',
  app: () => System.import('@prev-front/mf-template'),
  activeWhen: [isActive]
})

registerApplication({
  name: '@prev-front/mf-documentos',
  app: () => System.import('@prev-front/mf-documentos'),
  activeWhen: (location) =>
    location.pathname.startsWith('/peticoes/editor/') ||
    location.pathname.startsWith('/peticoes/view/') ||
    location.pathname.startsWith('/jurisprudencia/view/')
})

start({ urlRerouteOnly: true })
