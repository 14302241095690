//@ts-nocheck
import { registerApplication, start } from "single-spa";

const routes = [
  "/login",
  "/checkout",
  "/password-recovery",
  "/login/sem-escritorio",
  "/invitation-accepted",
  "/meu-escritorio",
  "/peticoes/view/",
  "/peticoes/editor/",
  '/configuracoes/financeiro/recibo/',
  "/jurisprudencia/view/",
  "/calculo/",
  "/revisao",
  "/liquidacao",
  "/valor-causa",
  "/beneficio-por-incapacidade",
  "/plano",
];

const isActive = (location) => {
  // Verifica se a URL atual não corresponde a nenhuma das rotas acima
  return !routes.some((route) => location.pathname.startsWith(route));
};

registerApplication({
  name: "@prev-front/mf-core",
  app: () => System.import("@prev-front/mf-core"),
  activeWhen: (location) =>
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/checkout") ||
    location.pathname.startsWith("/password-recovery") ||
    location.pathname.startsWith("/login/sem-escritorio") ||
    location.pathname.startsWith("/invitation-accepted") ||
    location.pathname.startsWith("/configuracoes/financeiro/recibo/"),
});

registerApplication({
  name: "@prev-front/meu-escritorio",
  app: () => System.import("@prev-front/meu-escritorio"),
  activeWhen: ["/meu-escritorio"],
});

registerApplication({
  name: "@prev-front/planos",
  app: () => System.import("@prev-front/planos"),
  activeWhen: ["/plano"],
});

registerApplication({
  name: "@prev-front/mf-fluxo-beneficio",
  app: () => System.import("@prev-front/mf-fluxo-beneficio"),
  activeWhen: ["/beneficio-por-incapacidade"],
});

registerApplication({
  name: "@prev-front/mf-calculos",
  app: () => System.import("@prev-front/mf-calculos"),
  activeWhen: (location) =>
    location.pathname.startsWith("/revisao") ||
    location.pathname.startsWith("/calculo") ||
    location.pathname.startsWith("/liquidacao") ||
    location.pathname.startsWith("/valor-causa"),
});

registerApplication({
  name: "@prev-front/mf-template",
  app: () => System.import("@prev-front/mf-template"),
  activeWhen: [isActive],
});

registerApplication({
  name: "@prev-front/mf-documentos",
  app: () => System.import("@prev-front/mf-documentos"),
  activeWhen: (location) =>
    location.pathname.startsWith("/peticoes/editor/") ||
    location.pathname.startsWith("/peticoes/view/") ||
    location.pathname.startsWith("/jurisprudencia/view/"),
});

start({ urlRerouteOnly: true });
